var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.isAdd ? "新增菜单" : "修改菜单",
        confirmLoading: _vm.confirmLoading
      },
      on: { ok: _vm.handleOkFunc },
      model: {
        value: _vm.isShow,
        callback: function($$v) {
          _vm.isShow = $$v
        },
        expression: "isShow"
      }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "infoFormModel",
          attrs: {
            model: _vm.saveObject,
            "label-col": { span: 6 },
            "wrapper-col": { span: 15 },
            rules: _vm.rules
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "组名称：", prop: "receiverGroupName" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.saveObject.receiverGroupName,
                  callback: function($$v) {
                    _vm.$set(_vm.saveObject, "receiverGroupName", $$v)
                  },
                  expression: "saveObject.receiverGroupName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "自动分账组", prop: "autoDivisionFlag" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.saveObject.autoDivisionFlag,
                    callback: function($$v) {
                      _vm.$set(_vm.saveObject, "autoDivisionFlag", $$v)
                    },
                    expression: "saveObject.autoDivisionFlag"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("否")])
                ],
                1
              ),
              _c("hr"),
              _c("p", { staticStyle: { color: "indianred" } }, [
                _vm._v(
                  "1. 自动分账组: 当订单分账模式为自动分账，该组下的所有正常分账状态的账号将作为订单分账对象"
                )
              ]),
              _c("p", { staticStyle: { color: "indianred" } }, [
                _vm._v(
                  "2. 每个商户仅有一个默认分账组， 当该组更新为自动分账时，其他组将改为否"
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }